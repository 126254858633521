import axiosIns from '@/libs/axios'

const ROOT_URL = '/service_photos'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataItem: {
      uploadCode: null,
      upload_image: null,
      id_services: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state, data) {
      if (data) {
        state.dataItem = {
          upload_images: [],
          id_services: null,
        }
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      return axiosIns
        .post(ROOT_URL, params)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const formData = new FormData()
      if (Number(postData.id_services) > 0) {
        formData.append('id_services', postData.id_services)
      } else {
        formData.append('id_services', '')
      }
      if (postData.uploadCode) {
        formData.append('uploadCode', postData.uploadCode)
      } else {
        formData.append('uploadCode', '')
      }
      if (postData.upload_image) {
        formData.append('upload_image', postData.upload_image)
      }
      return axiosIns
        .post(`${ROOT_URL}/saveData`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    resetDataItem({ commit }) {
      commit('RESET_DATA_ITEM', true)
    },
  },
}
