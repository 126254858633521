import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    service_requests: [],
    service_requestsCount: 0,
    service_request: {},
    service_requestSave: {
      message: null,
      status: null,
      id: null,
    },

  },
  getters: {
    getService_requests(state) {
      return state.service_requests
    },
    getService_requestsCount(state) {
      return state.service_requestsCount
    },
    getService_request(state) {
      return state.service_request
    },
    getService_requestSaveStatus(state) {
      return state.service_requestSave
    },
  },
  mutations: {
    SERVICE_REQUESTS_LIST(state, data) {
      state.service_requests = data
    },
    SERVICE_REQUEST_VIEW(state, data) {
      state.service_request = data
    },
    SERVICE_REQUESTS_COUNT(state, data) {
      state.service_requestsCount = data
    },
    SERVICE_REQUEST_SAVE(state, data) {
      state.service_requestSave = data
    },
  },
  actions: {
    service_requestsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/service_requests', data)
        .then(response => {
          commit('SERVICE_REQUESTS_LIST', response.data.data)
          commit('SERVICE_REQUESTS_COUNT', response.data.count)
          commit('SERVICE_REQUEST_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    service_requestView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'service_requests.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/service_requests', data)
        .then(response => {
          commit('SERVICE_REQUEST_VIEW', response.data.data)
          commit('SERVICE_REQUEST_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    service_requestSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/service_requests/saveData', data)
        .then(response => {
          commit('SERVICE_REQUEST_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
