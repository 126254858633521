import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    foreign_oil_ratios: [],
    foreign_oil_ratiosCount: 0,
    foreign_oil_ratio: {},
    foreign_oil_ratioSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getForeign_oil_ratios(state) {
      return state.foreign_oil_ratios
    },
    getForeign_oil_ratiosCount(state) {
      return state.foreign_oil_ratiosCount
    },
    getForeign_oil_ratio(state) {
      return state.foreign_oil_ratio
    },
    getForeign_oil_ratioSaveStatus(state) {
      return state.foreign_oil_ratioSave
    },
  },
  mutations: {
    FOREIGN_OIL_RATIOS_LIST(state, data) {
      state.foreign_oil_ratios = data
    },
    FOREIGN_OIL_RATIO_VIEW(state, data) {
      state.foreign_oil_ratio = data
    },
    FOREIGN_OIL_RATIOS_COUNT(state, data) {
      state.foreign_oil_ratiosCount = data
    },
    FOREIGN_OIL_RATIO_SAVE(state, data) {
      state.foreign_oil_ratioSave = data
    },
  },
  actions: {
    foreign_oil_ratiosList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/foreign_oil_ratios', data)
        .then(response => {
          commit('FOREIGN_OIL_RATIOS_LIST', response.data.data)
          commit('FOREIGN_OIL_RATIOS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    foreign_oil_ratioView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'foreign_oil_ratios.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/foreign_oil_ratios', data)
        .then(response => {
          commit('FOREIGN_OIL_RATIO_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    foreign_oil_ratioSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/foreign_oil_ratios/saveData', data)
        .then(response => {
          commit('FOREIGN_OIL_RATIO_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
