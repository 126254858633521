import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    appearances: [],
    appearancesCount: 0,
    appearance: {},
    appearanceSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getAppearances(state) {
      return state.appearances
    },
    getAppearancesCount(state) {
      return state.appearancesCount
    },
    getAppearance(state) {
      return state.appearance
    },
    getAppearanceSaveStatus(state) {
      return state.appearanceSave
    },
  },
  mutations: {
    APPEARANCES_LIST(state, data) {
      state.appearances = data
    },
    APPEARANCE_VIEW(state, data) {
      state.appearance = data
    },
    APPEARANCES_COUNT(state, data) {
      state.appearancesCount = data
    },
    APPEARANCE_SAVE(state, data) {
      state.appearanceSave = data
    },
  },
  actions: {
    appearancesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/appearances', data)
        .then(response => {
          commit('APPEARANCES_LIST', response.data.data)
          commit('APPEARANCES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    appearanceView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'appearances.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/appearances', data)
        .then(response => {
          commit('APPEARANCE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    appearanceSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/appearances/saveData', data)
        .then(response => {
          commit('APPEARANCE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
