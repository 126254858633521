export default [
  {
    path: '/reports/service-reports',
    name: 'ServiceReports',
    component: () => import('@/views/Reports/Service_reports/index.vue'),
    meta: {
      pageTitle: 'Servis Bakım Raporu',
      breadcrumb: [
        {
          text: 'Servis Bakım Raporu',
          active: true,
        },
      ],
      resource: 'SERVICE_REPORT',
      action: 'read',
    },
  },
]
