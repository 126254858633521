import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    totalIstatistics: {
      services: 0,
      requests: 0,
    },
    filterData: {
      sdate: null,
      edate: null,
      id_customers: null,
      id_machines: null,
    },
    criteriesData: {
      criteries: [],
    },
    criteriesList: [
      {
        id: 0,
        referance: 'analysed',
        title: 'Analiz Tarihi',
        units: null,
        testmethod: null,
      },
      {
        id: 1,
        referance: 'material_type',
        title: 'İşlenen Malzeme Cinsi',
        units: null,
        testmethod: null,
      },
      {
        id: 2,
        referance: 'used_oil',
        title: 'Kullanılan Yağ',
        units: null,
        testmethod: null,
      },
      {
        id: 3,
        referance: 'general_situation',
        title: 'Genel Durum',
        units: null,
        testmethod: null,
      },
      {
        id: 4,
        referance: 'appearance',
        title: 'Görünüm',
        units: null,
        testmethod: 'TM07-1',
      },
      {
        id: 35,
        referance: 'foreign_oil_ratio',
        title: 'Yabancı Yağ Oranı',
        units: null,
        testmethod: 'TM07-20',
      },
      {
        id: 5,
        referance: 'refractometer',
        title: 'Refraktometre Değeri',
        units: '%',
        testmethod: 'TM07-27',
      },
      {
        id: 6,
        referance: 'concentration',
        title: 'Konsantrasyon',
        units: null,
        testmethod: 'TM07-28',
      },
      {
        id: 7,
        referance: 'ph',
        title: 'pH',
        units: null,
        testmethod: 'ASTM D 1287',
      },
      {
        id: 8,
        referance: 'conductivity',
        title: 'İletkenlik',
        units: 'µs/cm',
        testmethod: 'ASTM D 1125',
      },
      {
        id: 9,
        referance: 'bacteria',
        title: 'Bakteri Testi',
        units: null,
        testmethod: 'TM07-15',
      },
      {
        id: 10,
        referance: 'mildew',
        title: 'Mantar Testi',
        units: null,
        testmethod: 'TM07-15',
      },
      {
        id: 11,
        referance: 'used_process',
        title: 'Kullanılan Proses',
        units: null,
        testmethod: null,
      },
      {
        id: 12,
        referance: 'used_product',
        title: 'Kullanılan Ürün',
        units: null,
        testmethod: null,
      },
      {
        id: 13,
        referance: 'total_alkalinity',
        title: 'Toplam Alkalite',
        units: 'ml/l',
        testmethod: null,
      },
      {
        id: 14,
        referance: 'total_acid',
        title: 'Toplam Asit',
        units: 'ml/l',
        testmethod: null,
      },
      {
        id: 15,
        referance: 'free_acid',
        title: 'Serbest Asit',
        units: 'ml/l',
        testmethod: null,
      },
      {
        id: 16,
        referance: 'accelerator',
        title: 'Hızlandırıcı',
        units: null,
        testmethod: null,
      },
      {
        id: 17,
        referance: 'phosphate_point',
        title: 'Fosfat Noktası',
        units: null,
        testmethod: null,
      },
      {
        id: 18,
        referance: 'iron_point',
        title: 'Demir Noktası',
        units: null,
        testmethod: null,
      },
      {
        id: 19,
        referance: 'zn',
        title: 'Zn (Çinko)',
        units: 'g/l',
        testmethod: null,
      },
      {
        id: 20,
        referance: 'mn',
        title: 'Mn(Mangan)',
        units: 'g/l',
        testmethod: null,
      },
      {
        id: 21,
        referance: 'ni',
        title: 'Ni(Nikel)',
        units: 'g/l',
        testmethod: null,
      },
      {
        id: 22,
        referance: 'free_alkalinity',
        title: 'Serbest Alkalite',
        units: 'ml/l',
        testmethod: null,
      },
      {
        id: 23,
        referance: 'total_oil_extract',
        title: 'Toplam Yağ Ekstre',
        units: 'ml/l',
        testmethod: null,
      },
      {
        id: 24,
        referance: 'free_fluorine',
        title: 'Serbest Flor',
        units: 'g/l',
        testmethod: null,
      },
      {
        id: 25,
        referance: 'sif6',
        title: 'SiF6',
        units: 'g/l',
        testmethod: null,
      },
      {
        id: 27,
        referance: 'al_point',
        title: 'Al Noktası',
        units: 'g/l',
        testmethod: null,
      },
      {
        id: 28,
        referance: 'zirconium',
        title: 'Zirkonyum',
        units: 'mg/l',
        testmethod: null,
      },
      {
        id: 36,
        referance: 'chlorine',
        title: 'Klor',
        units: 'mg/l',
        testmethod: null,
      },
      {
        id: 29,
        referance: 'water_hardness',
        title: 'Su Sertliği',
        units: 'od',
        testmethod: null,
      },
      {
        id: 30,
        referance: 'tin',
        title: 'Kalay',
        units: 'g/l',
        testmethod: null,
      },
      {
        id: 31,
        referance: 'naoh',
        title: 'NaOH',
        units: 'g/l',
        testmethod: null,
      },
      {
        id: 33,
        referance: 'coating_weight',
        title: 'Kaplama Ağırlığı',
        units: 'g/m2',
        testmethod: null,
      },
      {
        id: 34,
        referance: 'fe2',
        title: 'Fe+2',
        units: 'g/l',
        testmethod: null,
      },
    ],
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    filterData(state) {
      return state.filterData
    },
    CriteriesData(state) {
      return state.criteriesData
    },
    CriteriesList(state) {
      return state.criteriesList
    },
    totalIstatistics(state) {
      return state.totalIstatistics
    },
  },
  mutations: {
    REPORT_LIST(state, data) {
      state.dataList = data
    },
    TOTAL_ISTATISTICS(state, data) {
      state.totalIstatistics = data
    },
    RESET_REPORTS(state) {
      state.dataList = []
    },
    RESET_DATA(state) {
      state.filterData = {
        sdate: null,
        edate: null,
        id_customers: null,
        id_machines: null,
      }
    },
  },
  actions: {
    setReports({ commit }, params) {
      const data = JSON.stringify(params)
      // console.log(data)
      axiosIns
        .post('/reports/service_reports', data)
        .then(response => {
          commit('REPORT_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    setIstatistics({ commit }, params) {
      const data = JSON.stringify(params)
      // console.log(data)
      axiosIns
        .post('/reports/service_reports/getTotalIstatistics', data)
        .then(response => {
          commit('TOTAL_ISTATISTICS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    resetFilterData({ commit }) {
      commit('RESET_DATA', true)
    },
    resetReportData({ commit }) {
      commit('RESET_REPORTS', true)
    },

  },
}
