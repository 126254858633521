import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    material_types: [],
    material_typesCount: 0,
    material_type: {},
    material_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMaterial_types(state) {
      return state.material_types
    },
    getMaterial_typesCount(state) {
      return state.material_typesCount
    },
    getMaterial_type(state) {
      return state.material_type
    },
    getMaterial_typeSaveStatus(state) {
      return state.material_typeSave
    },
  },
  mutations: {
    MATERIAL_TYPES_LIST(state, data) {
      state.material_types = data
    },
    MATERIAL_TYPE_VIEW(state, data) {
      state.material_type = data
    },
    MATERIAL_TYPES_COUNT(state, data) {
      state.material_typesCount = data
    },
    MATERIAL_TYPE_SAVE(state, data) {
      state.material_typeSave = data
    },
  },
  actions: {
    material_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/material_types', data)
        .then(response => {
          commit('MATERIAL_TYPES_LIST', response.data.data)
          commit('MATERIAL_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    material_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'material_types.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/material_types', data)
        .then(response => {
          commit('MATERIAL_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    material_typeSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/material_types/saveData', data)
        .then(response => {
          commit('MATERIAL_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
