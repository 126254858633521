import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    services: [],
    servicesCount: 0,
    service: {
      id: null,
      document_no: null,
      bench_name: null,
      related_person: null,
      related_phone: null,
      notes: null,
      analysed: null,
      general_situation: null,
      refractometer: null,
      concentration: null,
      ph: null,
      conductivity: null,
      bacteria: null,
      mildew: null,
      refVal: null,
      total_alkalinity: null,
      total_acid: null,
      free_acid: null,
      accelerator: null,
      phosphate_point: null,
      iron_point: null,
      fe2: null,
      revised_note: null,
      email_content: null,
      email_status: null,
      formType: null,
      zn: null,
      mn: null,
      ni: null,
      free_alkalinity: null,
      total_oil_extract: null,
      free_fluorine: null,
      sif6: null,
      al_point: null,
      zirconium: null,
      chlorine: null,
      water_hardness: null,
      tin: null,
      naoh: null,
      coating_weight: null,
      id_material_types: null,
      id_used_oils: null,
      id_used_processes: null,
      id_used_products: null,
      id_appearances: null,
      id_foreign_oil_ratios: null,
      id_service_statuses: 1,
      id_machines: null,
      id_customers: null,
      id_customer_address: null,
      id_users: null,
      id_user_approval: null,
      approvaled: null,
      denied: null,
      uploadCode: null,
    },
    serviceHistory: [],
    serviceChart: {},
    serviceSave: {
      message: null,
      status: null,
      id: null,
    },
    exportPdfFile: {
      status: null,
      folder: null,
    },
  },
  getters: {
    getServices(state) {
      return state.services
    },
    getServicesCount(state) {
      return state.servicesCount
    },
    getService(state) {
      return state.service
    },
    getServiceHistory(state) {
      return state.serviceHistory
    },
    getServiceChart(state) {
      return state.serviceChart
    },
    getServiceSaveStatus(state) {
      return state.serviceSave
    },
    getExportPdfFile(state) {
      return state.exportPdfFile
    },
  },
  mutations: {
    SERVICES_LIST(state, data) {
      state.services = data
    },
    SERVICE_VIEW(state, data) {
      state.service = data
    },
    SERVICES_COUNT(state, data) {
      state.servicesCount = data
    },
    SERVICES_HISTORY(state, data) {
      state.serviceHistory = data
    },
    SERVICES_CHART(state, data) {
      state.serviceChart = data
    },
    SERVICE_SAVE(state, data) {
      state.serviceSave = data
    },
    RESET_DATA(state) {
      state.service = {
        id: null,
        document_no: null,
        bench_name: null,
        related_person: null,
        related_phone: null,
        notes: null,
        analysed: null,
        general_situation: null,
        refractometer: null,
        concentration: null,
        ph: null,
        conductivity: null,
        bacteria: null,
        mildew: null,
        refVal: null,
        total_alkalinity: null,
        total_acid: null,
        free_acid: null,
        accelerator: null,
        phosphate_point: null,
        iron_point: null,
        fe2: null,
        revised_note: null,
        email_content: null,
        email_status: null,
        formType: null,
        zn: null,
        mn: null,
        ni: null,
        free_alkalinity: null,
        total_oil_extract: null,
        free_fluorine: null,
        sif6: null,
        al_point: null,
        zirconium: null,
        chlorine: null,
        water_hardness: null,
        tin: null,
        naoh: null,
        coating_weight: null,
        id_material_types: null,
        id_used_oils: null,
        id_used_processes: null,
        id_used_products: null,
        id_appearances: null,
        id_foreign_oil_ratios: null,
        id_service_statuses: 1,
        id_machines: null,
        id_customers: null,
        id_customer_address: null,
        id_users: null,
        id_user_approval: null,
        approvaled: null,
        denied: null,
      }
    },
    EXPORT_PDF(state, data) {
      state.exportPdfFile = data
    },
  },
  actions: {
    servicesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/services', data)
        .then(response => {
          commit('SERVICES_LIST', response.data.data)
          commit('SERVICES_COUNT', response.data.count)
          commit('SERVICE_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    serviceView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'services.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/services', data)
        .then(response => {
          commit('SERVICE_VIEW', response.data.data)
          commit('SERVICE_SAVE', {
            message: null,
            status: null,
            id: null,
          })
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    serviceHistory({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/services/history', data)
        .then(response => {
          commit('SERVICES_HISTORY', response.data.data)
          commit('SERVICES_CHART', response.data.chartData)
        })
        .catch(error => console.error(error))
    },
    serviceSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/services/saveData', data)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    serviceDelete({ commit }, id) {
      return axiosIns
        .post('/services/deleteData', { id })
        .then(response => {
          commit('SERVICE_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    serviceApprove({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/services/approve', data)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    serviceDenied({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/services/denied', data)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    serviceRevised({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/services/revised', data)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    serviceMail({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/services/serviceMail', data)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    createPdf({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/services/createPdf', data)
        .then(response => {
          commit('EXPORT_PDF', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
